
import { Component, Vue, Prop } from "vue-property-decorator";

const infomationImg1 = require("@/assets/imgs/news/infor1.png"),
  infomationImg2 = require("@/assets/imgs/news/infor2.png"),
  infomationImg3 = require("@/assets/imgs/news/infor3.png"),
  infomationImg4 = require("@/assets/imgs/news/infor4.png");
@Component({
  name: "Infomation",
})
export default class Infomation extends Vue {
  @Prop() lang!: string;

  private infomationList = [
    {
      img: infomationImg1,
      title: "Courses & Activities",
      content: "   Kangaroo.Study 2025 S1 Term-1 Enrolment Guide",
      url: "/infomation/news",
    },
    {
      img: infomationImg2,
      title: "News & Updates",
      content:
        "“Jumpstart for New Sec 1 Stude-nts” Guidance Seminar Successf ...",
      url: "/infomation/index",
    },
    {
      img: infomationImg3,
      title: "Analysis & Guidance",
      content: "",
      url: "",
    },
    {
      img: infomationImg4,
      title: "Case Studies",
      content: "",
      url: "",
    },
  ];
  private goDetail(item: any) {
    item.url && this.$router.push(item.url);
  }
}
