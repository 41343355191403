
import { Component, Vue, Prop } from "vue-property-decorator";
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
const video1 = require('../../../assets/video/GEP/index.mp4'),
    video2 = require('../../../assets/video/PSLE/index.mp4'),
    video3 =require('../../../assets/video/PrimaryMathOlympiad/index.mp4'),
    video4=require('../../../assets/video/SecondaryMath/index.mp4')
@Component({
  name: "Workshops",
})
export default class Workshops extends Vue {
  @Prop() lang!: string;
  mounted() {
    this.getSwiper();
  }
  beforeDestroy() {
    this.swiper = null
  }
  // 轮播列表
  private slideList = [
    {
      type:'video',
      src:video1
    },
    {
      type:'video',
      src:video2
    },
    {
      type:'video',
      src:video3
    },
    {
      type:'video',
      src:video4
    }
  ]
  private swiper: any = null

  // 轮播初始化
  private getSwiper(): void {
     this.swiper = new Swiper(".workShops-swiper", {
      slidesPerView: 1.5, //设置slider容器能够同时显示的slides数量(carousel模式)。
      spaceBetween: -180, //在slide之间设置距离（单位px）。
      centeredSlides: true, //设置活动块居中
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',

      },
      autoplay: {
        stopOnLastSlide: true,
        disableOnInteraction: false,
        delay: 2000,
      },
      on: {
        slideChange: this.onSlideChange,
      },
    });
  }
  // 轮播改变
  private onSlideChange(): void{
    this.pauseAllVideos();
  }
  // 暂停正在播放的视频
  private pauseAllVideos(): void {
      const _this: any = this
      _this.$refs.videos.forEach((video: any) => {
        video.pause();
      });
  }
  private onVideoPlay(): void {
      this.swiper.autoplay.stop(); // 暂停 Swiper 自动播放
    }
  private onVideoPause(): void {
      this.swiper.autoplay.start(); // 恢复 Swiper 自动播放
    }
    private showNavigation = false
  private showSwiperNav(): void {
    this.showNavigation = true
  }
  private hideSwiperNav(): void {
    this.showNavigation = false
  }
 }
